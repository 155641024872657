<template>
 <div class="zshzPage">
    <img src="../assets/zshz/banner.png" class="banner" />
    <div class="bannerSj"></div>
    <div class="groups">
      <div class="groupParent">
        <img src="../assets/zshz/group1.png" class="img1" />
        <div class="blueBlock"></div>
        <img src="../assets/zshz/groupName1.png" class="img2" />
      </div>
      <div class="groupParent">
        <img src="../assets/zshz/group2.png" class="img1" />
        <div class="blueBlock"></div>
        <img src="../assets/zshz/groupName2.png" class="img2" />
      </div>
      <div class="groupParent active">
        <img src="../assets/zshz/group3.png" class="img1" />
        <div class="blueBlock"></div>
        <img src="../assets/zshz/groupName3.png" class="img2" />
      </div>
      <div class="groupParent">
        <img src="../assets/zshz/group4.png" class="img1" />
        <div class="blueBlock"></div>
        <img src="../assets/zshz/groupName4.png" class="img2" />
      </div>
      <div class="groupParent">
        <img src="../assets/zshz/group5.png" class="img1" />
        <div class="blueBlock"></div>
        <img src="../assets/zshz/groupName5.png" class="img2" />
      </div>
    </div>
    <!-- 选址筹建 -->
    <div class="orangeBlueBlock">
      <div class="orangeBlueBlock1">选址筹建</div>
      <div class="orangeBlueBlock2"></div>
    </div>
    <div class="xzcjContent">
      <div class="xzcjLeft">
        <img class="cardImg" src="../assets/zshz/xzcj1.png" />
        <div class="fontAreaLeft">
          <div class="xzcjName">市场支持</div>
          <div class="orangeBlock"></div>
        </div>
        <div class="xzcjDesc">
          市场人员根据场地边周边市场情况<br />
          给出市场定位 价格定位 推广策略等个性化俱乐部解决方案
        </div>
        <img class="cardImg" src="../assets/zshz/xzcj3.png" />
        <div class="fontAreaLeft">
          <div class="xzcjName">团队搭建</div>
          <div class="orangeBlock"></div>
        </div>
        <div class="xzcjDesc">
          简历筛选 远程面试 全套招聘体系<br />
          薪资架构体系输出
        </div>
      </div>
      <div class="xzcjCenter"></div>
      <div class="xzcjRight">
        <div class="fontAreaRight">
          <div class="orangeBlock"></div>
          <div class="xzcjName">校区选址支持</div>
        </div>
        <div class="xzcjDesc">
          大数据选址报告<br />
          科学有效的协助校区选址 定址
        </div>
        <img class="cardImg" src="../assets/zshz/xzcj2.png" />
        <div class="fontAreaRight">
          <div class="orangeBlock"></div>
          <div class="xzcjName">装修支持</div>
        </div>
        <div class="xzcjDesc">
          整套VI\CI\SI 全套品牌支持体系输出
        </div>
        <img class="cardImg" src="../assets/zshz/xzcj4.png" />
      </div>
    </div>
    <!-- 分岗培训 -->
    <div class="orangeBlueBlock">
      <div class="orangeBlueBlock1">分岗培训</div>
      <div class="orangeBlueBlock2"></div>
    </div>
    <div class="fgpxContent">
      <div class="fgpxContentC">
        <img class="cardImg" src="../assets/zshz/fgpx1.png" />
        <div class="orangeBlock"></div>
        <div class="fgpxDesc">教师分岗培训</div>
      </div>
      <div class="fgpxContentC">
        <img class="cardImg" src="../assets/zshz/fgpx2.png" />
        <div class="orangeBlock"></div>
        <div class="fgpxDesc">标准教案、课件、详案</div>
      </div>
    </div>
    <!-- 招生指导 -->
    <div class="orangeBlueBlock">
      <div class="orangeBlueBlock1">招生指导</div>
      <div class="orangeBlueBlock2"></div>
    </div>
    <div class="xzcjContent">
      <div class="xzcjLeft">
        <img class="cardImg" src="../assets/zshz/zszd1.png" />
        <div class="fontAreaLeft">
          <div class="xzcjName">宣传物料设计</div>
          <div class="orangeBlock"></div>
        </div>
        <div class="xzcjDesc">
          宣品海报 朋友圈文案 校区软装
        </div>
        <img class="cardImg" src="../assets/zshz/zszd3.png" />
        <div class="fontAreaLeft">
          <div class="xzcjName">营销渠道推广</div>
          <div class="orangeBlock"></div>
        </div>
        <div class="xzcjDesc">
          异业合作 渠道合作 利润模型<br />
          提供全套解决方案
        </div>
      </div>
      <div class="xzcjCenter"></div>
      <div class="xzcjRight">
        <div class="fontAreaRight">
          <div class="orangeBlock"></div>
          <div class="xzcjName">全年活动方案</div>
        </div>
        <div class="xzcjDesc">
          全年活动方案设计 招生渠道 营销裂变<br />
          社群转化多种招生方式
        </div>
        <img class="cardImg" src="../assets/zshz/zszd2.png" />
        <div class="fontAreaRight">
          <div class="orangeBlock"></div>
          <div class="xzcjName">运营数据分析</div>
        </div>
        <div class="xzcjDesc">
          销售以结果为导向 根据销售额 评效<br />
          有效分析销售问题
        </div>
        <img class="cardImg" src="../assets/zshz/zszd4.png" />
      </div>
    </div>
    <!-- 品牌推广 -->
    <div class="orangeBlueBlock">
      <div class="orangeBlueBlock1">品牌推广</div>
      <div class="orangeBlueBlock2"></div>
    </div>
    <div class="pptgContent">
      <div>
        <div class="pptg1">
          <img src="../assets/zshz/pptg1.png" />
          <div class="pptgName">集团品牌推广</div>
          <div class="pptgDesc">
            大搜、信息流、抖音、小红书<br />
            全年全案品牌推广策略，提升品牌知名度
          </div>
        </div>
        <div class="pptg2">
          <img src="../assets/zshz/pptg2.png" />
          <div class="pptgName">全年活动联动</div>
          <div class="pptgDesc">
            月月有活动、周周有方案、季季有主题，<br />
            全年活动联动助力俱乐部成长；
          </div>
        </div>
      </div>
      <div>
        <div class="pptg2">
          <img src="../assets/zshz/pptg3.png" />
          <div class="pptgName">全国大赛推广</div>
          <div class="pptgDesc">
            全国性高含金量赛事为孩子提供更多平台
          </div>
        </div>
        <div class="pptg1">
          <img src="../assets/zshz/pptg4.png" />
          <div class="pptgName">权威证书背书</div>
          <div class="pptgDesc">
            航模、无人机领域国内、国际大咖助力品牌成长
          </div>
        </div>
      </div>
    </div>
    <!-- 运营管理 -->
    <div class="orangeBlueBlock">
      <div class="orangeBlueBlock1">运营管理</div>
      <div class="orangeBlueBlock2"></div>
    </div>
    <div class="yygls">
      <div class="yyglParent">
        <img src="../assets/zshz/yygl1.png" class="img1" />
        <img src="../assets/zshz/yyglName1.png" class="img2" />
        <div class="blueBlock"></div>
        <div class="desc">
          岗位职责 薪资体系 团队文化<br />
          KPI考核等全体系输出
        </div>
      </div>
      <div class="yyglParent active">
        <img src="../assets/zshz/yygl2.png" class="img1" />
        <img src="../assets/zshz/yyglName2.png" class="img2" />
        <div class="blueBlock"></div>
        <div class="desc">
          日常管理 营销管理 财务管理<br />
          销售管理 教学管理等全体系输出
        </div>
      </div>
      <div class="yyglParent">
        <img src="../assets/zshz/yygl3.png" class="img1" />
        <img src="../assets/zshz/yyglName3.png" class="img2" />
        <div class="blueBlock"></div>
        <div class="desc">
          在线学习平台 线上课程 线下引流<br />
          教学效果外化 活动展现等全体系输出
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'zshz',
  components: {}
};
</script>
<style lang="less">
.zshzPage {
  .banner {
    width: 100%;
    display: block;
  }
  .bannerSj {
    width: 0;
    height: 0;
    border-left: 85/19.2vw solid transparent;
    border-right: 85/19.2vw solid transparent;
    border-top: 85/19.2vw solid #007dd3;
    margin: 0 auto;
  }

  .orangeBlueBlock {
    width: 413/19.2vw;
    height: 114/19.2vw;
    position: relative;
    margin: 90/19.2vw auto 94/19.2vw;
    > div {
      width: 400/19.2vw;
      height: 100/19.2vw;
    }
    .orangeBlueBlock1 {
      background: #ff9327;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 40/19.2vw;
      font-family: "Microsoft YaHei";
      color: rgb(255, 255, 255);
      line-height: 40/19.2vw;
      font-style: italic;
      font-weight: bold;
    }
    .orangeBlueBlock2 {
      background: #007dd3;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
    }
  }
  .orangeBlock {
    background-color: #ff932b;
    width: 56/19.2vw;
    height: 7/19.2vw;
  }

  .groups {
    width: 1397/19.2vw;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .groupParent {
      padding: 39/19.2vw 39/19.2vw 17/19.2vw;
      width: 265/19.2vw;
      background: #fef4ea;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 148/19.2vw 0 0;
      &.active {
        background: #e6f2fc;
        position: relative;
        top: -48/19.2vw;
        .img1 {
          box-shadow: 0px 0px 10px 10px rgba(255, 229, 207, 0.85);
        }
      }
      .img1 {
        width: 213/19.2vw;
        height: 254/19.2vw;
        box-shadow: 0px 0px 20px 20px rgba(250, 252, 252, 0.85);
        margin-bottom: 40/19.2vw;
      }
      .blueBlock {
        width: 31/19.2vw;
        height: 4/19.2vw;
        background: #007dd3;
        margin-bottom: 12/19.2vw;
      }
      .img2 {
        height: 16/19.2vw;
      }
    }
  }

  .xzcjContent {
    display: flex;
    justify-content: center;
    margin: 0 auto 110/19.2vw;
    .cardImg {
      width: 568/19.2vw;
      height: 378/19.2vw;
      box-shadow: 0px 0px 10px 10px rgba(255, 229, 207, 0.85);
      margin: 0 20/19.2vw;
    }

    .fontAreaLeft {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 80/19.2vw;
    }
    .fontAreaRight {
      display: flex;
      align-items: center;
      margin-top: 80/19.2vw;
    }
    .xzcjName {
      font-size: 24/19.2vw;
      font-family: "Microsoft YaHei";
      font-weight: bold;
      color: #007dd3;
      line-height: 1.2;
      font-style: italic;
    }

    .xzcjDesc {
      font-size: 18/19.2vw;
      font-family: "Microsoft YaHei";
      color: rgb(95, 95, 95);
      line-height: 1.2;
      margin: 18/19.2vw 0 65/19.2vw;
      font-style: italic;
    }
    .xzcjLeft {
      width: 588/19.2vw;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding-top: 85/19.2vw;
      .xzcjDesc {
        text-align: right;
        margin-right: 74/19.2vw;
      }
      .orangeBlock {
        margin-left: 21/19.2vw;
      }
    }
    .xzcjCenter {
      width: 11/19.2vw;
      height: 1434/19.2vw;
      background: #007dd3;
    }
    .xzcjRight {
      width: 588/19.2vw;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-top: 174/19.2vw;
      .xzcjDesc {
        margin-left: 74/19.2vw;
        text-align: left;
      }
      .orangeBlock {
        margin-right: 21/19.2vw;
      }
    }
  }
  .fgpxContent {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    .cardImg {
      width: 568/19.2vw;
      height: 378/19.2vw;
      box-shadow: 0px 0px 10px 10px rgba(255, 229, 207, 0.85);
      margin: 0 20/19.2vw;
    }
    .orangeBlock {
      margin: 59/19.2vw 0 29/19.2vw;
    }
    .fgpxContentC {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .fgpxDesc {
      font-size: 24/19.2vw;
      font-family: "Microsoft YaHei";
      font-weight: bold;
      color: #007dd3;
      line-height: 1.2;
      font-style: italic;
    }
  }

  .pptgContent {
    margin: 0 auto;
    > div {
      display: flex;
      justify-content: center;
      &:nth-child(1) {
        align-items: flex-end;
        margin-bottom: 30/19.2vw;
      }
      &:nth-child(2) {
        padding-left: 100/19.2vw;
      }
    }
    .pptgName {
      font-size: 24/19.2vw;
      font-family: "Microsoft YaHei";
      font-weight: bold;
      color: #007dd3;
      line-height: 1.2;
      font-style: italic;
      margin: 26/19.2vw 0 16/19.2vw;
    }
    .pptgDesc {
      font-size: 18/19.2vw;
      font-family: "Microsoft YaHei";
      color: rgb(95, 95, 95);
      line-height: 1.2;
      font-style: italic;
      text-align: center;
    }
    .pptg1 {
      background-color: rgb(255, 255, 255);
      width: 530/19.2vw;
      height: 511/19.2vw;
      box-shadow: 0px 0px 10px 10px rgba(255, 229, 207, 0.85);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 47/19.2vw 63/19.2vw 0 61/19.2vw;
      margin-right: 44/19.2vw;
      img {
        width: 406/19.2vw;
        height: 309/19.2vw;
      }
    }
    .pptg2 {
      background-color: rgb(255, 255, 255);
      width: 482/19.2vw;
      height: 466/19.2vw;
      box-shadow: 0px 0px 10px 10px rgba(206, 228, 246, 0.85);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 47/19.2vw 63/19.2vw 0 61/19.2vw;
      margin-right: 44/19.2vw;
      img {
        width: 364/19.2vw;
        height: 278/19.2vw;
      }
    }
  }

  .yygls {
    margin: 0 auto 140/19.2vw;
    display: flex;
    justify-content: center;
    .yyglParent {
      padding: 39/19.2vw 0 0;
      width: 386/19.2vw;
      height: 580/19.2vw;
      background: #e6f2fc;
      display: flex;
      flex-direction: column;
      align-items: center;
      //   margin: 148/19.2vw 0 0;
      &:nth-child(1) {
        margin-top: 240/19.2vw;
      }
      &.active {
        background: #fef4ea;
        margin: 120/19.2vw 32/19.2vw 0;
        .img1 {
          box-shadow: 0px 0px 10px 10px rgba(206, 228, 246, 0.85);
        }
      }
      .img1 {
        width: 310/19.2vw;
        height: 369/19.2vw;
        box-shadow: 0px 0px 10px 10px rgba(255, 229, 207, 0.85);
        margin-bottom: 40/19.2vw;
      }
      .blueBlock {
        width: 31/19.2vw;
        height: 4/19.2vw;
        background: #007dd3;
        margin-bottom: 12/19.2vw;
      }
      .img2 {
        height: 18/19.2vw;
        margin-bottom: 9/19.2vw;
      }
      .desc {
        text-align: center;
        font-size: 20/19.2vw;
        font-family: "Microsoft YaHei";
        color: rgb(38, 38, 38);
        line-height: 1.2;
      }
    }
  }
}

</style>
